
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-form'
})

export default class extends Vue {
  @Prop({default: ''}) readonly initialQuery: string;
  @Prop({default: false}) readonly customSearch: boolean;

  private inputQuery: string = '';
  protected autocompleteData: object = {
    products: [],
    categories: [],
    pages: [],
  };
  private _throttle: number;
  private autocompleteLoading: boolean = false;
  private autocompleteRemove: boolean = false;
  private showAutocomplete: boolean = false;
  private defaultPlaceholder: string = '/area/web/default/assets/images/default-placeholder.png';

  private async fetchAutocompleteData(): Promise<void> {
    clearTimeout(this._throttle);
    this._throttle = window.setTimeout(async () => {
      this.autocompleteRemove = this.inputQuery.length > 0;
      if (this.inputQuery.length > 1) {
        this.autocompleteLoading = true;
        try {
          const {data} = await this.$solarClient.get(this.customSearch ? '/api/catalog/search/product/full/list' : '/api/catalog/search/autocomplete', {
            timeout: 1800000,
            params: {
              query: this.inputQuery,
              limit: 6,
            }
          });

          if (this.customSearch) {
            this.autocompleteData['products'] = data.items;
            this.showAutocomplete = data.items.length > 0;
          } else {
            this.autocompleteData = data.items;
            this.showAutocomplete = data.items['products'].length > 0 || data.items['categories'].length > 0 || data.items['pages'].length > 0;
          }
        } catch (e) {
          console.error(e);
          this.showAutocomplete = false;
        } finally {
          this.autocompleteLoading = false;
        }
      }
    }, 500);
  }

  private clearSearchInput(): void {
    this.inputQuery = '';
    this.showAutocomplete = false;
    this.autocompleteRemove = false;
  }

  private get locale(): string {
    return this.$config.get('locale');
  }

  private imageSrc(product: object): string {
    if ( ! product['default_image']) {
      return this.defaultPlaceholder;
    }

    return `${this.$solarCdnBaseUrl}/cdn/resize-extended/rs:fill/w:85/extend:true/background:255:255:255/path/media/catalog/product${product['default_image']['url']}`;
  }

  private addProduct(product: object): void {
    this.$store.dispatch('QuickBuy/setProductsToAdd', product);
  }

  private get getProductRows(): Array<object> {
    return this.$store.getters['QuickBuy/getProductsToAdd'];
  }

  private addProductsToQuickBuy(): void {
    this.$store.dispatch('QuickBuy/setProductRow');
    this.clearSearchInput();
  }

  private isSelectedProduct(id: number): boolean {
    return this.getProductRows.some((product: object) => product['entity_id'] === id);
  }

  private created(): void {
    this.inputQuery = this.initialQuery;
  }
}
